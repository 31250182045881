import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';

type TabOption = {
  value: string;
  name?: string;
};

interface SxProps {
  [key: string]: any;
}

interface IDropdownProps {
  fullWidth: boolean;
  labelId: string;
  id: string;
  dataTestId?: string;
  defaultValue?: any;
  type: 'number' | 'string';
  value: number | string;
  onChangeHandler: (e: any) => void;
  options: Array<TabOption> | [];
  selectSxProps?: SxProps;
  menuItemSxProps?: SxProps;
  isDisabled?: boolean;
  ariaLabel?: string | undefined;
}
const DropdownTypes = {
  NUMBER: 'number',
  STRING: 'string',
};
const Dropdown = (props: IDropdownProps): JSX.Element => {
  const {
    fullWidth,
    labelId,
    id,
    dataTestId,
    defaultValue,
    type,
    value,
    onChangeHandler,
    options,
    selectSxProps,
    menuItemSxProps,
    isDisabled = false,
    ariaLabel = '',
  } = props;
  return (
    <FormControl fullWidth={fullWidth} id={labelId}>
      <FormLabel id={`form-${labelId}`}>
        <Select
          aria-label={ariaLabel}
          labelId={labelId}
          id={id}
          data-testid={dataTestId}
          value={value || defaultValue}
          onChange={onChangeHandler}
          sx={selectSxProps}
          disabled={isDisabled}
          inputProps={{ 'aria-label': ariaLabel, id: `input-${id}` }}
        >
          {options?.map((item: TabOption, index) => (
            <MenuItem
              key={index}
              value={type === DropdownTypes.NUMBER ? Number(index) : item.value}
              sx={menuItemSxProps}
            >
              {item?.name ? item?.name : item?.value}
            </MenuItem>
          ))}
        </Select>
      </FormLabel>
    </FormControl>
  );
};

export default Dropdown;
