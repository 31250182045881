import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContactPreference } from '../../../features/registrationSlice';
import { RootState } from '../../../store/store';
import RegWorkflowFactory from '../RegWorkflowFactory';
import { RegWorkflowContainer } from '../RegistrationWorkflow.style';
import { getQuery } from '../../../lib/utils/common';
import { addOrUpdateCrRegistrationDetails } from '../../../services/RegisterUserService';
import { getCookie } from 'lib/utils/enrollmentUtil';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { CustomMobileInput } from './CustomMobileInput';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { updateCRQueryParamList } from '../../../features/crQueryParamSlice';

const ContactPreference = (props: any): JSX.Element => {
  const storedAnswers = useSelector(
    (state: RootState) => state.registrationDetails.contactPreference
  );
  const isSMSDefaultOptIn = () => {
    const show =
      props?.fields?.consentMediumList &&
      props?.fields?.consentMediumList.some((consentMedium: any) => {
        const fieldName = consentMedium?.fields?.key?.value;
        const defaultOptInState = consentMedium?.fields?.defaultOptIn?.value;
        const defaultHideState = consentMedium?.fields?.hideFromUI?.value;
        if (fieldName === 'SMS' && !!defaultOptInState && !!defaultHideState) {
          return true;
        }
        return false;
      });
    return show;
  };

  const getFormDataDefaultValue = (existingValues: any) => {
    return {
      ...existingValues,
      ...storedAnswers,
      isMobile: isSMSDefaultOptIn(),
    };
  };

  useEffect(() => {
    if (storedAnswers?.agree) {
      setIsConsentsAccepted(storedAnswers?.agree);
    }
  }, [storedAnswers]);

  const {
    control,
    setError,
    clearErrors,
    formState: { errors, isValid },
    getValues,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: async () => getFormDataDefaultValue({}),
  });

  const [isConsentsAccepted, setIsConsentsAccepted] = useState(false);
  const router = useRouter();
  const { query } = router;

  const dispatch = useDispatch();
  const formValues = watch();
  const [enableNext, setEnableNext] = useState<boolean>(false);

  useEffect(() => {
    if (
      Object.keys(errors).length > 0 ||
      (props?.fields?.isPhoneNumberRequired?.value &&
        (formValues.contactNumber === '' || formValues.contactNumber === undefined)) ||
      (props?.fields?.isConsentRequired?.value && !formValues.agree)
    ) {
      setEnableNext(false);
    } else if (showIsMobileOption?.value && isSMSDefaultOptIn() && formValues.isMobile === false) {
      setEnableNext(false);
    } else {
      setEnableNext(true);
    }
  }, [formValues]);

  const phoneNumberRequiredErrorMessage = props?.fields?.phoneNumberRequiredErrorMessage;
  const phoneNumberRegex = props?.fields?.phoneNumberRegex;
  const phoneNumberPlaceholder = props?.fields?.phoneNumberPlaceholder;
  const phoneNumberLabel = props?.fields?.phoneNumberLabel;
  const mobileNumberValidationMessageOnSMSConsent =
    props?.fields?.mobileNumberValidationMessageOnSMSConsent;
  const isPhoneNumberRequired = props?.fields?.isPhoneNumberRequired;
  const isConsentRequired = props?.fields?.isConsentRequired;
  const hideConsentCheckbox = props?.fields?.hideConsentCheckbox;
  const enableAtleastOneContactMediumMandatedOutOfVisible =
    props?.fields?.enableAtleastOneContactMediumMandatedOutOfVisible;

  const enableAtleastOneContactMediumErrorMessage =
    props?.fields?.enableAtleastOneContactMediumErrorMessage;
  const defaultOptinAll = props?.fields?.defaultOptinAll;
  const defaultOptIn = props?.fields?.defaultOptIn;
  const consentTitle = props?.fields?.consentTitle;
  const consentSubTitle = props?.fields?.consentSubTitle;
  const consentRequiredErrorMessage = props?.fields?.consentRequiredErrorMessage;
  const consentMediumList = props?.fields?.consentMediumList;
  const IsMobileLabel = props?.fields?.IsMobileLabel;
  const isMobileRequired = props?.fields?.isPhoneNumberRequired?.value;
  const phoneNumberRegexErrorMessage = props?.fields?.phoneNumberRegexErrorMessage;
  const showIsMobileOption = props?.fields?.showIsMobileOption;

  const registrationCRDetails = async () => {
    try {
      const guestId = getCookie('bx_guest_ref') || '';
      const { data: res } = await addOrUpdateCrRegistrationDetails('CR', guestId);
      if (res.myProfileVerificationToken) {
        sessionStorage.setItem('myProfileVerificationToken', res.myProfileVerificationToken);
      }
      dispatch(updateCRQueryParamList({}));
      return res;
    } catch (_e) {
      return false;
    }
  };

  const renderError = (name: string) => (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Box
          sx={{
            color: (theme) => theme.palette.error.main,
            width: '130px',
            fontSize: '14px',
            marginTop: '1rem',
          }}
        >
          {message}
        </Box>
      )}
    />
  );

  const renderDefaultOptainConsents = (props: any) => {
    return (
      <>
        <Controller
          name={'agree'}
          control={control}
          defaultValue={true}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        {props?.fields?.consentMediumList &&
          props?.fields?.consentMediumList.map((consentMedium: any, index: any) => {
            const fieldName = `${consentMedium?.fields?.key?.value}`;
            return (
              <Controller
                key={index}
                name={fieldName}
                control={control}
                defaultValue={consentMedium?.fields?.defaultOptIn?.value}
                render={({ field }) => <input type="hidden" {...field} />}
              />
            );
          })}
      </>
    );
  };

  const handleClick = async (event: any, url: any, action: any) => {
    event.preventDefault();
    const formData = getValues();
    dispatch(setContactPreference(formData));

    if (action === 'back') {
      if (url != 'http://') {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      } else {
        history.back();
      }
      return false;
    }

    trigger();
    if (!isValid) {
      return false;
    }
    const res = await registrationCRDetails();
    if (res !== false) {
      router.push({
        pathname: url,
        query: getQuery(query),
      });
    }
    return false;
  };
  const shouldHideTheBox = () => {
    const retVal = props?.fields?.consentMediumList.every((consentMedium: any) => {
      return consentMedium?.fields?.hideFromUI?.value === true;
    });
    return retVal;
  };

  if (!props?.fields) return <></>;
  return (
    <RegWorkflowContainer>
      <RegWorkflowFactory
        title={props?.fields?.title}
        subTitle={props?.fields?.subTitle}
        currentPage={5}
        totalCount={5}
        buttonsItems={props?.fields?.ctaLinks}
        handleClick={handleClick}
        enableNext={enableNext}
      >
        <form>
          <Grid container>
            <Controller
              name="contactNumber"
              key={props?.name?.value}
              control={control}
              defaultValue={storedAnswers?.contactNumber}
              rules={{
                required: isPhoneNumberRequired.value ? phoneNumberRequiredErrorMessage?.value : '',
                validate: (value) => {
                  const regEx = RegExp(phoneNumberRegex?.fields?.value?.value);
                  return regEx.test(value) ? true : phoneNumberRegexErrorMessage?.value ?? '';
                },
              }}
              render={({ field }) => (
                <>
                  <CustomMobileInput
                    label={phoneNumberLabel}
                    control={control}
                    isMobileLabel={IsMobileLabel}
                    isMobileRequired={isMobileRequired}
                    placeholder={phoneNumberPlaceholder?.value}
                    mobileNumberValidationMessageOnSMSConsent={
                      mobileNumberValidationMessageOnSMSConsent
                    }
                    errors={errors}
                    field={field}
                    showIsMobileOption={showIsMobileOption}
                    rules={{
                      validate: (value) => {
                        if (
                          (isSMSDefaultOptIn() && value === false) ||
                          (formValues.SMS === true && value === false)
                        ) {
                          return mobileNumberValidationMessageOnSMSConsent?.value;
                        }
                        return true;
                      },
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <br></br>

          {defaultOptinAll?.value ? (
            renderDefaultOptainConsents(props)
          ) : (
            <Grid container>
              <Box>
                <Controller
                  name="agree"
                  key={props?.name?.value}
                  control={control}
                  rules={{
                    required: isConsentRequired?.value ? consentRequiredErrorMessage?.value : '',
                    validate: (value, formValues) => {
                      if (value) {
                        setIsConsentsAccepted(value);
                      } else {
                        setIsConsentsAccepted(value);
                      }

                      const atleastOneContactMediumRequired =
                        enableAtleastOneContactMediumMandatedOutOfVisible?.value;
                      const isConsentSelected = consentMediumList.some((consentMedium: any) => {
                        const fieldName = consentMedium?.fields?.key?.value;
                        return formValues[fieldName];
                      });

                      if (atleastOneContactMediumRequired && !isConsentSelected) {
                        return enableAtleastOneContactMediumErrorMessage?.value;
                      }
                      return true;
                    },
                  }}
                  defaultValue={defaultOptIn?.value}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={
                          <>
                            {hideConsentCheckbox?.value ? (
                              <input type="hidden" {...field} />
                            ) : (
                              <Checkbox
                                checked={!!field?.value}
                                sx={{
                                  alignSelf: 'flex-start',
                                  padding: '0 9px',
                                  background: '#ffffff',
                                }}
                                {...field}
                              />
                            )}
                          </>
                        }
                        label={
                          <Box sx={{ paddingLeft: defaultOptIn?.value ? '10px' : 'none' }}>
                            <RichText sx={{ color: '#000000' }} field={consentTitle}></RichText>
                          </Box>
                        }
                        sx={{
                          color: '#444444',
                        }}
                      />
                    </>
                  )}
                />
              </Box>

              <Grid
                container
                sx={{
                  display:
                    (isConsentsAccepted || defaultOptIn?.value) && !shouldHideTheBox()
                      ? 'block'
                      : 'none',
                }}
              >
                <Box
                  sx={{
                    display: 'block',
                    background: '#f2f2f2',
                    marginTop: '0.5rem',
                    padding: '1.4rem',
                    borderRadius: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#444444',
                    }}
                  >
                    <Text field={consentSubTitle}></Text>
                  </Typography>
                  <FormGroup row={true}>
                    {consentMediumList &&
                      consentMediumList.map((consentMedium: any, index: any) => {
                        const fieldName = `${consentMedium?.fields?.key?.value}`;
                        if (!consentMedium?.fields?.hideFromUI?.value) {
                          return (
                            <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
                              <Controller
                                name={fieldName}
                                key={props?.name?.value}
                                control={control}
                                rules={{
                                  validate: (value, values) => {
                                    if (showIsMobileOption?.value) {
                                      if (values.SMS && !!values.isMobile === false) {
                                        setError('isMobile', {
                                          type: 'manual',
                                          message: mobileNumberValidationMessageOnSMSConsent.value,
                                        });
                                      } else if (
                                        !!values.SMS == false &&
                                        !!values.isMobile === false
                                      ) {
                                        clearErrors('isMobile');
                                      }
                                    }

                                    if (consentMedium?.fields?.isRequired?.value && !value) {
                                      return consentMedium?.fields?.isRequiredErrorMessage?.value;
                                    }
                                    return true;
                                  },
                                }}
                                defaultValue={
                                  fieldName === 'Email'
                                    ? true
                                    : consentMedium?.fields?.defaultOptIn?.value
                                }
                                render={({ field }) => (
                                  <>
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          disableRipple
                                          sx={{
                                            paddingInline: '0',
                                          }}
                                          defaultChecked={true}
                                          {...field}
                                          checked={!!field?.value}
                                        />
                                      }
                                      label={<Text field={consentMedium?.fields?.name} />}
                                      sx={{
                                        display: 'flex',
                                        margin: 0,
                                        gap: '6px',
                                        color: '#444444',
                                        '& .MuiFormControlLabel-label': {
                                          fontFamily: 'Proxima Nova',
                                          fontSize: '14px',
                                          lineHeight: '16px',
                                        },
                                      }}
                                    />
                                    {/* {JSON.stringify(errors)} */}
                                    {renderError && renderError(fieldName)}
                                  </>
                                )}
                              />
                            </Grid>
                          );
                        } else if (
                          consentMedium?.fields?.hideFromUI?.value &&
                          consentMedium?.fields?.isRequired?.value
                        ) {
                          return (
                            <Controller
                              key={index}
                              name={fieldName}
                              control={control}
                              defaultValue={true}
                              render={({ field }) => <input type="hidden" {...field} />}
                            />
                          );
                        } else if (
                          consentMedium?.fields?.hideFromUI?.value &&
                          consentMedium?.fields?.defaultOptIn?.value
                        ) {
                          return (
                            <Controller
                              key={index}
                              name={fieldName}
                              control={control}
                              defaultValue={true}
                              render={({ field }) => <input type="hidden" {...field} />}
                            />
                          );
                        } else {
                          return <></>;
                        }
                      })}
                  </FormGroup>
                </Box>
              </Grid>
              {renderError && renderError('agree')}
            </Grid>
          )}
        </form>
      </RegWorkflowFactory>
    </RegWorkflowContainer>
  );
};

export default ContactPreference;
