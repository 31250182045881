import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Controller } from 'react-hook-form';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  PRIMARY_CONSENT_MEDIUM,
  SECONDARY_CONSENT_MEDIUM,
} from '../../../components/ContactInfo/constants';

type ConsentMediumProps = {
  type: any;
  name: any;
  consentMediumList: any;
  consentSubTitle: any;
  consentTitle: any;
  isMandatory: any;
  control: any;
  errors: any;
  rules: any;
  renderError?: any;
  formElements?: any;
  setError: any;
  clearErrors: any;
  setValue: any;
};

interface IShowSubtitle {
  [index: string]: boolean;
}
const renderHiddenConsentMedium = (props: any) => {
  return (
    <>
      <Controller
        name={props?.formElements?.key?.value}
        control={props.control}
        defaultValue={true}
        render={({ field }) => <input type="hidden" {...field} />}
      />
    </>
  );
};
const renderConsentMediums = (
  props: any,
  showSubtitle: IShowSubtitle,
  setShowSubtitle: Dispatch<SetStateAction<IShowSubtitle>>
) => {
  if (!props?.formElements?.hideConsentCheckbox?.value) {
    return (
      <Grid className="consent-section">
        <Controller
          name={props?.name?.value}
          control={props.control}
          rules={{
            validate: (value: boolean[], formValues) => {
              const atleastOneContactMediumRequired =
                props?.formElements?.enableAtleastOneContactMediumMandatedOutOfVisible?.value;

              if (props?.formElements?.isRequired?.value && !value) {
                return props?.formElements?.requiredFieldMessage?.value;
              }
              const isValid = props?.consentMediumList.some((consentMedium: any) => {
                const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
                return formValues[fieldName];
              });

              if (atleastOneContactMediumRequired && !isValid) {
                return props?.formElements?.enableAtleastOneContactMediumErrorMessage?.value;
              }
            },
          }}
          render={({ field }) => {
            return (
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        alignSelf: 'flex-start',
                        padding: '0 9px',
                        background: '#ffffff',
                      }}
                      {...field}
                      defaultChecked={field.value}
                    />
                  }
                  label={
                    <Box>
                      <RichText field={props?.consentTitle}></RichText>
                      <Grid sx={{ display: field?.value ? 'block' : 'none' }}>
                        <Box
                          sx={{
                            display: showSubtitle[props?.name?.value] ? 'block' : 'none',
                            background: '#f2f2f2',
                            marginTop: '0.5rem',
                            padding: '1.4rem',
                            borderRadius: '10px',
                          }}
                        >
                          <Typography
                            onClick={(event: React.SyntheticEvent) => event.preventDefault()}
                          >
                            <Text field={props?.consentSubTitle}></Text>
                          </Typography>
                          <FormGroup aria-label={props?.formElements?.ariaLabel?.value} row={true}>
                            {props?.consentMediumList &&
                              props?.consentMediumList.map((consentMedium: any, index: any) => {
                                const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
                                if (!consentMedium?.fields?.hideFromUI?.value) {
                                  if (!showSubtitle[props?.name?.value]) {
                                    setShowSubtitle({
                                      ...showSubtitle,
                                      [props?.name?.value]: true,
                                    });
                                  }
                                  return (
                                    <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
                                      <Controller
                                        name={fieldName}
                                        key={props?.name?.value}
                                        control={props.control}
                                        rules={{
                                          validate: (value, formValues) => {
                                            if (
                                              consentMedium?.fields?.isRequired?.value &&
                                              !value
                                            ) {
                                              return consentMedium?.fields?.isRequiredErrorMessage
                                                ?.value;
                                            }

                                            const isValid = props?.consentMediumList.some(
                                              (consentMedium: any) => {
                                                const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
                                                return formValues[fieldName];
                                              }
                                            );

                                            if (
                                              !isValid &&
                                              formValues[props?.name?.value] &&
                                              props?.formElements
                                                ?.enableAtleastOneContactMediumMandatedOutOfVisible
                                                ?.value
                                            ) {
                                              props.setError(props?.name?.value, {
                                                message:
                                                  props?.formElements
                                                    ?.enableAtleastOneContactMediumErrorMessage
                                                    ?.value,
                                              });
                                            } else if (
                                              isValid === true &&
                                              props?.formElements
                                                ?.enableAtleastOneContactMediumMandatedOutOfVisible
                                                ?.value
                                            ) {
                                              props.clearErrors(props?.name?.value);
                                            }
                                          },
                                        }}
                                        render={({ field }) => (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  sx={{
                                                    paddingInline: '0',
                                                  }}
                                                  {...field}
                                                  defaultChecked={field.value}
                                                />
                                              }
                                              label={<Text field={consentMedium?.fields?.name} />}
                                              sx={{
                                                display: 'flex',
                                                margin: 0,
                                                gap: '6px',
                                                '& .MuiFormControlLabel-label': {
                                                  fontFamily: 'Proxima Nova',
                                                  fontSize: '14px',
                                                  lineHeight: '16px',
                                                },
                                              }}
                                            />
                                            {props?.renderError && props?.renderError(fieldName)}
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  );
                                } else if (
                                  consentMedium?.fields?.hideFromUI?.value &&
                                  consentMedium?.fields?.isRequired?.value
                                ) {
                                  return (
                                    <Controller
                                      key={index}
                                      name={fieldName}
                                      control={props.control}
                                      defaultValue={true}
                                      render={({ field }) => <input type="hidden" {...field} />}
                                    />
                                  );
                                } else if (
                                  consentMedium?.fields?.hideFromUI?.value &&
                                  consentMedium?.fields?.defaultOptIn?.value
                                ) {
                                  return (
                                    <Controller
                                      key={index}
                                      name={fieldName}
                                      control={props.control}
                                      defaultValue={true}
                                      render={({ field }) => <input type="hidden" {...field} />}
                                    />
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Box>
                  }
                  labelPlacement="end"
                />
                {props?.renderError && props?.renderError(props?.name?.value)}
              </FormControl>
            );
          }}
        />
      </Grid>
    );
  } else {
    return (
      <>
        <Controller
          name={props?.name?.value}
          control={props.control}
          rules={props.rules}
          defaultValue={true}
          render={() => (
            <FormControl>
              <FormControlLabel
                control={
                  <Grid style={{ display: 'none' }}>
                    <input value={'true'} type="hidden" name={props?.name?.value} />
                  </Grid>
                }
                label={
                  <Box sx={{ pl: '1rem', pr: '1.4rem', pb: '1.4rem' }}>
                    <RichText
                      onClick={(event: React.SyntheticEvent) => event.preventDefault()}
                      field={props?.consentTitle}
                    ></RichText>
                    <Box
                      sx={{
                        display: showSubtitle[props?.name?.value] ? 'block' : 'none',
                        background: '#f2f2f2',
                        marginTop: '0.5rem',
                        padding: '1.4rem',
                        borderRadius: '10px',
                      }}
                    >
                      <Typography>
                        <Text field={props?.consentSubTitle}></Text>
                      </Typography>
                      <FormGroup aria-label={props?.formElements?.ariaLabel?.value} row={true}>
                        {props?.consentMediumList &&
                          props?.consentMediumList.map((consentMedium: any, index: any) => {
                            const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
                            if (!consentMedium?.fields?.hideFromUI?.value) {
                              if (!showSubtitle[props?.name?.value]) {
                                setShowSubtitle({ ...showSubtitle, [props?.name?.value]: true });
                              }
                              return (
                                <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
                                  <Controller
                                    name={fieldName}
                                    key={props?.name?.value}
                                    control={props.control}
                                    rules={{
                                      // required: {
                                      //   value: consentMedium?.fields?.isRequired?.value,
                                      //   message:
                                      //     consentMedium?.fields?.isRequiredErrorMessage?.value,
                                      // },
                                      validate: (value, formValues) => {
                                        if (consentMedium?.fields?.isRequired?.value && !value) {
                                          return consentMedium?.fields?.isRequiredErrorMessage
                                            ?.value;
                                        }
                                        const isValid = props.consentMediumList.some(
                                          (consentMedium: any) => {
                                            const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
                                            return formValues[fieldName];
                                          }
                                        );

                                        if (
                                          !isValid &&
                                          props?.formElements
                                            ?.enableAtleastOneContactMediumMandatedOutOfVisible
                                            ?.value
                                        ) {
                                          props.setError(props?.name?.value, {
                                            message:
                                              props?.formElements
                                                ?.enableAtleastOneContactMediumErrorMessage?.value,
                                          });
                                        } else if (
                                          isValid === true &&
                                          props?.formElements
                                            ?.enableAtleastOneContactMediumMandatedOutOfVisible
                                            ?.value
                                        ) {
                                          props.clearErrors(props?.name?.value);
                                        }
                                        return true;
                                      },
                                    }}
                                    render={({ field }) => (
                                      <>
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Checkbox
                                              disableRipple
                                              sx={{
                                                paddingInline: '0',
                                              }}
                                              defaultChecked={field.value}
                                              {...field}
                                            />
                                          }
                                          label={<Text field={consentMedium?.fields?.name} />}
                                          sx={{
                                            display: 'flex',
                                            margin: 0,
                                            gap: '6px',
                                            '& .MuiFormControlLabel-label': {
                                              fontFamily: 'Proxima Nova',
                                              fontSize: '14px',
                                              lineHeight: '16px',
                                            },
                                          }}
                                        />
                                        {props?.renderError && props?.renderError(fieldName)}
                                      </>
                                    )}
                                  />
                                </Grid>
                              );
                            } else if (
                              consentMedium?.fields?.hideFromUI?.value &&
                              consentMedium?.fields?.isRequired?.value
                            ) {
                              return (
                                <Controller
                                  key={index}
                                  name={fieldName}
                                  control={props.control}
                                  defaultValue={true}
                                  render={({ field }) => <input type="hidden" {...field} />}
                                />
                              );
                            } else if (
                              consentMedium?.fields?.hideFromUI?.value &&
                              consentMedium?.fields?.defaultOptIn?.value
                            ) {
                              return (
                                <Controller
                                  key={index}
                                  name={fieldName}
                                  control={props.control}
                                  defaultValue={true}
                                  render={({ field }) => <input type="hidden" {...field} />}
                                />
                              );
                            } else {
                              return <></>;
                            }
                          })}
                      </FormGroup>
                    </Box>
                  </Box>
                }
                labelPlacement="end"
              />
              {props?.renderError && props?.renderError(props?.name?.value)}
            </FormControl>
          )}
        />
      </>
    );
  }
};
const renderDefaultOptInAllMediums = (props: any) => {
  return (
    <>
      <Controller
        name={props?.formElements?.key?.value}
        control={props.control}
        defaultValue={true}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      {props?.consentMediumList &&
        props?.consentMediumList.map((consentMedium: any, index: any) => {
          const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
          return (
            <Controller
              key={index}
              name={fieldName}
              control={props.control}
              defaultValue={true}
              render={({ field }) => <input type="hidden" {...field} />}
            />
          );
        })}
    </>
  );
};

const renderDefaultOptainConsent = (props: ConsentMediumProps) => {
  return (
    <>
      <Controller
        name={props?.formElements?.key?.value}
        control={props.control}
        defaultValue={true}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      {props?.consentMediumList &&
        props?.consentMediumList.map((consentMedium: any, index: any) => {
          const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
          return (
            <Controller
              key={index}
              name={fieldName}
              control={props.control}
              defaultValue={consentMedium?.fields?.defaultOptIn?.value}
              render={({ field }) => <input type="hidden" {...field} />}
            />
          );
        })}
    </>
  );
};
const ConsentMedium = (props: ConsentMediumProps): JSX.Element => {
  const [showSubtitle, setShowSubtitle] = useState<IShowSubtitle>({
    [PRIMARY_CONSENT_MEDIUM]: false,
    [SECONDARY_CONSENT_MEDIUM]: false,
  });

  if (props?.formElements?.defaultOptInAll?.value) {
    return <>{renderDefaultOptInAllMediums(props)}</>;
  } else if (
    props?.formElements?.hideFromUI?.value &&
    !props?.formElements?.isRequired?.value &&
    props?.formElements?.defaultOptIn?.value
  ) {
    return <>{renderDefaultOptainConsent(props)}</>;
  } else if (props?.formElements?.hideFromUI?.value && !props?.formElements?.isRequired?.value) {
    return <>{renderHiddenConsentMedium(props)}</>;
  } else {
    return <>{renderConsentMediums(props, showSubtitle, setShowSubtitle)}</>;
  }
};

export default ConsentMedium;
