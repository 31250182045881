/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MONTH_MAPPER } from '../transformers/contactInfo.transform';
import { addOrUpdateCrRegisterUser, fetchProfileDetailsForCR } from '../lib/utils/axiosCDPFetcher';
import { post } from '../lib/utils/axiosFetcher';
import { post as cdpPost } from '../lib/utils/axiosCDPFetcher';
import { store } from '../store/store';
import axios from 'axios';
import { ICOIAndICommunities } from '../types/registerUserTypes';
import { getCookie } from 'cookies-next';
import omit from 'lodash/omit';

export const getStringValFromArray = (
  dataList: any,
  keyName: string,
  subKey: string,
  isIndex?: boolean
) => {
  const arr =
    dataList?.map((data: any) => ({
      sitecoreId: data?.sitecoreId || '',
      id: isIndex ? (data?.[keyName] + 1).toString() : data?.[keyName],
      text: data?.[subKey],
    })) || [];
  return { items: [...arr], lastUpdated: new Date() };
};

export const getStringValFromArrayForCDP = (
  dataList: any,
  keyName: string,
  subKey: string,
  isIndex?: boolean
) => {
  const arr =
    dataList?.map((data: any) => ({
      sitecoreId: data?.sitecoreId || '',
      id: isIndex ? (data?.[keyName] + 1).toString() : data?.[keyName],
      text: data?.[subKey],
    })) || [];
  return arr;
};

export const getCommunities = (dataList: any) => {
  const communityList: any = [];
  dataList &&
    dataList?.map((data: any) => {
      if (data?.communities && data?.communities.length > 0) {
        communityList.push(...data?.communities);
      }
    });
  const uniqueCommunityList = communityList?.reduce((list: any, currentItem: any) => {
    if (!list.find((item: any) => item.value === currentItem.value)) {
      list.push(currentItem);
    }
    return list;
  }, []);
  return uniqueCommunityList;
};

export const addOrUpdateCrRegistrationDetails = async (source: string, guestRef?: string) => {
  const state = store.getState();
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  const conditionOfInterest = getStringValFromArray(
    state?.registrationDetails?.selectedCOIList,
    'value',
    'key'
  );

  const communityList = getCommunities(state?.registrationDetails?.selectedCOIList);
  const communities = getStringValFromArray(communityList, 'value', 'key');
  const crQueryParams = state?.crQueryParam?.queryParamList || {};
  const crQueryParamWithoutGclId = omit(crQueryParams, ['gclid', 'fbclid', 'atid']);
  const secondaryConsent = {
    isSecondaryConsentAccepted:
      (state?.registrationDetails?.registration?.agree && 'True') || 'False',
    secondaryConsentAcceptedDateTime: new Date(),
    isSecondaryContactMediumPhone: state?.registrationDetails?.contactPreference?.Phone
      ? 'Yes'
      : 'No',
    isSecondaryContactMediumEmail: state?.registrationDetails?.contactPreference?.Email
      ? 'Yes'
      : 'No',
    isSecondaryContactMediumSMS:
      state?.registrationDetails?.contactPreference?.textMessaging ||
      state?.registrationDetails?.contactPreference?.SMS
        ? 'Yes'
        : 'No',
    isSecondaryContactMediumAutomatedDialer: state?.registrationDetails?.contactPreference
      ?.Automateddialer
      ? 'Yes'
      : 'No',
    isSecondaryContactMediumArtificialVoice: state?.registrationDetails?.contactPreference
      ?.Artificialvoice
      ? 'Yes'
      : 'No',
    isSecondaryContactMediumPreRecordedVoice: state?.registrationDetails?.contactPreference[
      'Pre-recordedvoice'
    ]
      ? 'Yes'
      : 'No',
    isSecondaryContactMediumDirectMail: state?.registrationDetails?.contactPreference?.DirectEmail
      ? 'Yes'
      : 'No',
    isSecondaryContactMediumInsightSurveys: state?.registrationDetails?.contactPreference
      ?.InsightSurveys
      ? 'Yes'
      : 'No',
    combinedConsentAccepted: null,
    combinedConsentAcceptedDateTime: null,
  };

  const travelDistance = state?.registrationDetails?.locationDetails?.travelDistance || '';
  const interestedInAtHomeTrials = state?.registrationDetails?.locationDetails?.homeTrial || '';
  const selectedRelevantItems = getStringValFromArray(
    state?.registrationDetails?.patientDetails,
    'index',
    'name',
    true
  );
  const zipCode = state?.registrationDetails?.locationDetails?.zipcode || '';
  const payload = {
    ref: guestRef,
    email: state?.registrationDetails?.registration?.email || '',
    firstName: state?.registrationDetails?.aboutYourSelf?.firstName || '',
    lastName: state?.registrationDetails?.aboutYourSelf?.lastName || '',
    postCode: state?.registrationDetails?.locationDetails?.zipcode || '',
    phoneNumbers: state?.registrationDetails?.contactPreference?.contactNumber?.split() || [],
    zipCode,
    dateOfBirth: state?.registrationDetails?.dob?.dob,
    gender: state?.registrationDetails?.dob?.gender,
    isMobile: state?.registrationDetails?.contactPreference.isMobile,
    dataExtension: {
      UtmParameter: crQueryParamWithoutGclId,
      gclId: crQueryParams?.gclid || '',
      atId: crQueryParams?.atid || '',
      fbclId: crQueryParams?.fbclid || '',
      conditionOfInterest: conditionOfInterest,
      communities: communities,
      travelDistance,
      source,
      secondaryConsent,
      selectedRelevantItems,
      interestedInAtHomeTrials: interestedInAtHomeTrials,
      latitude: state?.registrationDetails?.locationDetails?.latitude || 0,
      longitude: state?.registrationDetails?.locationDetails?.longitude || 0,
    },
    browserId: getCookie(cookieName),
  };
  try {
    // Make the API call to fetch CDP Data
    const response = await addOrUpdateCrRegisterUser(
      `/api/contacts/AddOrUpdateCrRegistrationDetails/${guestRef}?guestType=customer`,
      payload
    );
    return response;
  } catch (_error) {}
};

export const pad = (n: any) => {
  if (n < 10) return '0' + n;
  return n;
};

export const addOrUpdateCrMyProfile = async (
  source: string,
  formData: any,
  isVerifiedEmail: boolean,
  guestRef?: string,
  language?: string,
  sendMailingListId?: any,
  siteSettingsId?: any
) => {
  const secondaryConsent = formData?.SecondaryConsentMedium
    ? {
        isSecondaryConsentAccepted: formData?.SecondaryConsentMedium ? 'True' : 'False',
        secondaryConsentAcceptedDateTime: new Date(),
        isSecondaryContactMediumPhone: formData?.SecondaryConsentMediumPhone ? 'Yes' : 'No',
        isSecondaryContactMediumEmail: formData?.SecondaryConsentMediumEmail ? 'Yes' : 'No',
        isSecondaryContactMediumSMS: formData?.SecondaryConsentMediumSMS ? 'Yes' : 'No',
        isSecondaryContactMediumAutomatedDialer: formData?.SecondaryConsentMediumAutomatedDialer
          ? 'Yes'
          : 'No',
        isSecondaryContactMediumArtificialVoice: formData?.SecondaryConsentMediumArtificialVoice
          ? 'Yes'
          : 'No',
        isSecondaryContactMediumPreRecordedVoice: formData?.SecondaryConsentMediumPrerecordedVoice
          ? 'Yes'
          : 'No',
        isSecondaryContactMediumDirectMail: formData?.SecondaryConsentMediumDirectEmail
          ? 'Yes'
          : 'No',
        isSecondaryContactMediumInsightSurveys: formData?.SecondaryConsentMediumInsightSurveys
          ? 'Yes'
          : 'No',
      }
    : null;

  const getDOB = (formData: any) => {
    if (formData?.Day && formData?.Month && formData?.Year) {
      return `${formData.Year}-${pad(
        MONTH_MAPPER[formData.Month as keyof typeof MONTH_MAPPER]
      )}-${formData.Day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  };

  const dob = getDOB(formData);

  const payload = {
    ref: guestRef,
    email: formData?.Email || '',
    firstName: formData?.FirstName || '',
    lastName: formData?.LastName || '',
    postCode: formData?.Zipcode || '',
    address: formData?.CrAddress || '',
    state: formData?.State || '',
    city: formData?.City || '',
    isMobile: formData?.isMobile ? formData?.isMobile : false,
    phoneNumbers: formData.ContactNumber?.split() || [],
    zipCode: formData?.Zipcode,
    dateOfBirth: dob,
    gender: formData?.Gender,
    dataExtension: {
      source,
      secondaryConsent,
    },
    emailVerified: isVerifiedEmail ? 'True' : 'False',
    mailingListId: sendMailingListId,
    siteSettingsId: siteSettingsId,
    language: language,
  };

  try {
    // Make the API call to fetch CDP Data
    const response = await addOrUpdateCrRegisterUser(
      `/api/contacts/AddOrUpdateCrRegistrationDetails/${guestRef}?guestType=customer`,
      payload
    );
    return response;
  } catch (_error) {}
};

const PROFILE_VERIFY_TOKEN_KEY = 'myProfileVerificationToken';

export const getProfileData = async () => {
  const myProfileVerificationToken = sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
  if (myProfileVerificationToken) {
    try {
      const response = await fetchProfileDetailsForCR(myProfileVerificationToken);
      return response;
    } catch (_error) {}
  }
};

interface ISendMailProps {
  email: string;
  mailIdList: string;
  emailType: string;
  baseUrl: string;
  myProfileUrl: string;
  communityPageLink: string;
  resendAttemptCount: number;
  siteSettingsId: string;
  language: string;
}
export const callSendEmailDapApi = async (payload: ISendMailProps) => {
  try {
    await post('/Send/AddContactsToCRMailingList', payload);
  } catch (_error) {}
};

export const checkUserRegisterOrNot = async (email: string): Promise<any> => {
  try {
    const URL = `/api/cdp-api?url=/api/contacts/ValidateCrEmailId&email=${email}`;
    const response = await axios.get(URL);
    return !!response?.data?.isEmailExist;
  } catch (_error) {}
};

export const updateCommunitiesToCDP = async (
  COIData: ICOIAndICommunities[],
  communitiesData: ICOIAndICommunities[],
  actionType: string
) => {
  try {
    const PROFILE_VERIFY_TOKEN_KEY = 'myProfileVerificationToken';

    const myProfileVerificationToken = sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
    const data = {
      myProfileToken: myProfileVerificationToken,
      conditionOfInterest: {
        items: COIData,
      },
      communities: {
        items: communitiesData,
      },
      [actionType]: true,
    };
    const cdpRes = await cdpPost('/api/contacts/UpdateConditionCommunitiesForMyProfile', data);
    return cdpRes;
  } catch (error) {
    return false;
  }
};
