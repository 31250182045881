import { Field, ImageField } from '@sitecore-jss/sitecore-jss-react';
import { ComponentProps } from 'lib/component-props';

export enum TemplateNames {
  OneAndTwoColumn = 'OneAndTwoColumn',
  InformationalSteps = 'InformationalSteps',
  FlexiContainer = 'FlexiContainer',
  FeatureHighlights = 'FeatureHighlights',
}

export interface IComponentListItem {
  templateName: TemplateNames;
  fields: any;
}

export interface IStudyItem {
  fields: {
    studyName?: Field<string>;
    conditionName?: Field<string>;
    condition?: {
      fields?: { description?: Field<string>; key?: Field<string>; value?: Field<string> };
    };
    protocol?: {
      fields?: {
        studyName: Field<string>;
        protocolId: Field<string>;
      };
    };
    componentList?: Array<IComponentListItem>;
    btnColor?: Field<string>;
  };
}

export interface IStudySwitcherProps extends ComponentProps {
  fields: {
    heading?: Field<string>;
    icon?: ImageField;
    iconWithTitleVariant?: Field<string>;
    isConditionSwitcher?: Field<boolean>;
    studyItemList: Array<IStudyItem>;
  };
}

export interface ITabOption {
  value: string;
}
