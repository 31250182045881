import React from 'react';
import Box from '@mui/material/Box';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Typography from '@mui/material/Typography';
import { IThreeStepInformationProps } from './ThreeStepInformation.types';
import ThreeStepItem from './ThreeStepItem';
import ThreeStepItemVar2 from './ThreeStepItemVar2';
import ThreeStepItemVar3 from './ThreeStepItemVar3';
import { StDTPWrapper, StDTPGridContainer } from '../shared/Container/Container.styles';
import { useTheme } from '@mui/material/styles';

const fallbackText = {
  value: '',
};

enum Variants {
  Variant1 = 'variant1',
  Variant2 = 'variant2',
  Variant3 = 'variant3',
}

function isHexadecimalColor(str: string) {
  const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexColorPattern.test(str);
}

const VerticalLine: React.FC<any> = (props: any) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '155px',
        width: props?.type === Variants.Variant3 ? '1.5px' : '1px',
        background: (theme): string =>
          props?.type === Variants.Variant3 ? '#7bbcd9' : theme.palette.themeColor.main,
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      }}
    ></Box>
  );
};

const ThreeStepInformation: React.FC<IThreeStepInformationProps> = (props) => {
  const theme = useTheme();
  const themeColor = theme.palette?.themeColor?.main || 'black';

  if (!props?.fields) return <></>;
  const steps = props?.fields?.steps;
  const title = props?.fields?.title || fallbackText;
  const description = props?.fields?.description || fallbackText;
  const variant = props?.fields?.variant || fallbackText;
  const isVariant1 = variant?.value === Variants.Variant1;
  const isVariant2 = variant?.value === Variants.Variant2;
  const customBgColor = props?.fields?.backgroundColor;
  const bgColor =
    customBgColor?.value && isHexadecimalColor(customBgColor.value)
      ? customBgColor.value
      : 'inherit';
  const RenderSteps = () => {
    return (
      <StDTPGridContainer
        id={'how-does-it-work-steps'}
        display="flex"
        justifyContent="space-evenly"
        alignItems="end"
        sx={{
          marginTop: '0px',
          paddingTop: '2rem',
          backgroundColor: bgColor,
          [theme.breakpoints.down(isVariant1 ? 431 : 'lg')]: {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            rowGap: isVariant1 ? '1.5rem' : '3rem',
          },
        }}
      >
        {Array.isArray(steps) &&
          steps.map((item, index) =>
            isVariant1 ? (
              <ThreeStepItem key={index} {...item} />
            ) : isVariant2 ? (
              <React.Fragment key={index}>
                {index === 0 ? null : <VerticalLine />}
                <ThreeStepItemVar2 key={index} {...item} />
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                {index === 0 ? null : <VerticalLine type={variant?.value} />}
                <ThreeStepItemVar3 key={index} {...item} />
              </React.Fragment>
            )
          )}
      </StDTPGridContainer>
    );
  };

  return (
    <StDTPWrapper>
      <StDTPGridContainer
        id={'how-does-it-work'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          marginBottom: '0px',
          backgroundColor: bgColor,
        }}
      >
        <Typography color={themeColor} variant="h2" fontSize="32px !important" fontWeight="500">
          <Text field={title} />
        </Typography>
        <Typography
          color={themeColor}
          variant="h4"
          fontSize="1.125rem"
          fontWeight="600"
          paddingTop="1rem"
          textAlign="center"
        >
          <Text field={description} />
        </Typography>
      </StDTPGridContainer>
      <RenderSteps />
    </StDTPWrapper>
  );
};

export default ThreeStepInformation;
